import React from 'react';
import Layout from '../components/layout/layout';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Seo from '../components/seo/seo';
import ArrowSvg from '../assets/um-arrow-pagination.svg';
import Post from '../components/post/post';
import StickyBox from '../components/sticky-box/sticky-box';
import CompareBox from '../domain/compare-box/compare-box';
import config from '../../config.json';
import CalendarSvg from '../assets/um-calendar-article.svg';
import {ReferrerCookie} from "../components/referrer/referrer";

const BlogList: React.FC = ({ data }: any) => {
  const wpPage = data.wpPage;
  const posts = data.allWpPost.edges;

  const referrer = ReferrerCookie();

  let postsCount = config.POSTS_PER_PAGE;

  const storagePosts = data.allWpPost.edges.slice(postsCount);

  if(typeof window !== 'undefined'){
    sessionStorage.setItem('posts', JSON.stringify(storagePosts));
  }
  if(typeof document !== `undefined`) {
    document.addEventListener('scroll', function(e) {
      if(Boolean(document.getElementsByClassName('infinite-scroll').length) && typeof window !== 'undefined') {
        let scrollPosition = window.scrollY;
        var lastPost = document.querySelector('.post-box:last-child');

        if(scrollPosition > lastPost.offsetTop) {
          let postsList = JSON.parse(sessionStorage.getItem('posts'));
          let postsToShow = postsList.slice(0, postsCount);
          
          sessionStorage.setItem('posts', JSON.stringify(postsList.slice(postsCount)));

          let html = '';

          postsToShow.forEach(post => {
              let image = getImage(post.node.featuredImage.node.localFile)!;
              let imageUrl = image.images.fallback.src.replace(/(\s)/, "\\ ");
              
              let excerpt = post.node.zajawkaBlog.zajawka;
              if (excerpt.length >= 160) {
                excerpt = excerpt.slice(0, 160).split(' ').slice(0, -1).join(' ') + '... ';
              }

              html += `<div class="post-box"><a href="/blog/`+ post.node.slug +`/">
                        <div class="h-full w-full overflow-hidden rounded-md bg-white shadow-light">
                          <img class="max-h-[300px]" src="`+ imageUrl +`" width="750" height="400" data-main-image="" sizes="(min-width: 750px) 750px, 100vw" />
                          <div class="p-5 lg:p-7">
                            <h2 class="text-xl font-semibold lg:min-h-[60px]">
                              `+ post.node.title +`
                            </h2>
                            <div class="mt-5 flex items-center">
                              <img src="`+ CalendarSvg +`" alt="" />
                              <span class="pl-2 font-secondary">`+ post.node.dateGmt +`</span>
                            </div>
                            <p class="mt-3 font-secondary">`+ excerpt +`</p>
                          </div>
                        </div>
                      </a></div>`;
          });

          document.querySelector('.infinite-scroll').innerHTML += html;
        }
      }
    });
  }

  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.focuskw}
        pathname="/blog/"
      />
      <div className="bg-blueLight">
        <div className="container mx-auto px-4 py-6 lg:py-20">
          <h1 className="text-center text-2xl font-semibold lg:text-4xl">
            Blog
          </h1>
          <div className="mt-5 mb-7 w-full text-center lg:mb-14">
            <Link to="/" className="hover:text-primary">
              Strona główna
            </Link>
            <img src={ArrowSvg} className="inline-block pl-2" />
            <span className="pl-2 text-primary">Blog</span>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-20">
            <div className="col-span-2">
              <div className="grid auto-rows-max grid-cols-1 gap-4 lg:gap-14 xl:grid-cols-2 infinite-scroll">
                {posts.slice(0, postsCount).map((post: any) => {
                  const image = getImage(
                    post.node.featuredImage.node.localFile
                  )!;
                  return (
                    <div className="post-box">
                      <Post
                        post={post.node}
                        image={image}
                        type="blog"
                        key={post.node.id}
                        referrer={ReferrerCookie()}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <StickyBox>
              <CompareBox referrer={referrer}/>
            </StickyBox>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
{
  allWpPost(
    filter: { categories: { nodes: { elemMatch: { slug: { eq: "blog" } } } } }
    sort: { order: DESC, fields: dateGmt }
  ) {
    edges {
      node {
        dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
        id
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, height: 400, quality: 90)
              }
            }
          }
        }
        modifiedGmt(formatString: "DD.MM.YYYY")
        zajawkaBlog {
          zajawka
        }
      }
    }
  }
  wpPage(slug: { eq: "blog" }) {
    seo {
      metaDesc
      title
      focuskw
    }
  }
}
`;
